<template>
	<div>
		<div class='row'>
			<div class='col-md-6'>
				<div class='left-side card-body' :class='{"light": lightmode}'>
					<img class='logo-img branded-logo' :src='branding.brand_logo'> 

					<h4 class="card-title mt-4">{{branding.platform_name}}</h4>
					<h6 class="card-subtitle mb-4">You may have followed an incorrect link to get here. Please enter the <b class='upper'>Election Code</b> found in your voting instruction email to continue to your election. <br/><br/><i class='grey'>Note, this is not the same as the Voting PIN found in voting instructions.</i></h6>
					
				</div>
			</div>
			<div class='col-md-6'>
				<div class='right-side card-body'>
					<div class='inv-blokc d-none d-md-block'>
						&nbsp;
					</div>
					<div class="form-group">
						<label :class='{"light": lightmode}'>Election Code</label>
						<input :class="{'is-invalid': get_error('pin').length > 0}" type="text" name='pin' class="form-control" placeholder='###-###-###' v-model='election_code'>
						<span v-show="get_error('pin').length > 0" class="invalid-feedback">{{ get_error('pin') }}</span>
					</div>

					<ErrorMessage v-if='error.length > 0' :message='error' />
					
					<div class='text-center text-md-left'>
						<button class='btn btn-primary' @click='next'>Load Election</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang='scss'>
	.time-item{
		display: inline-block;
		color: white;
		font-size: 1.2rem;
	}
	.left-side{
		.upper{
			text-transform: uppercase;
		}
		.branded-logo{
			max-width: 200px;
		}
		.card-title{
			font-size: 3rem;
		}
		*{
			color: white;
		}
		.grey{
			color: #BBB;
		}

		&.light{
			*{
				color: #000;
			}
		}
	}
	.right-side{
		* {
			color: white;
		}
		.inv-blokc{
			height: 150px;
		}
		label{
			font-size: 1.5rem;
		}
		input{
			background-color: white;
			border: 1px solid #666;
			border-radius: 5px;
			color: #000 !important;
			font-size: 18px;
		}

		*.light{
			color: #000;
		}
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";

import { VALIDATE_CODE } from "@/store/actions.type";

export default {
	name: 'OpenElection',
	components: {
		ErrorMessage
	},
	data(){
		return {
			errors: {
				pin: ''
			},
			security: '',
			election_code: '',
			error: '',
			timeinterval: undefined,
			times: [
				{ id: 1, text: "Day", time: 1 },
				{ id: 2, text: "Hr", time: 1 },
				{ id: 3, text: "Min", time: 1 },
				{ id: 4, text: "Sec", time: 1 }
			]
		};
	},
	computed: {
		lightmode(){
			return this.branding.lightmode;
		},
		...mapGetters(['election', 'branding'])
	},
	methods: {
		next(){
			if(this.election_code.length < 3){
				this.errors.pin = 'Please enter your election code.';
				return;
			}
			this.$router.push({ name: 'vote', params: { election_id: this.election_code }});
		},
		get_error(key){
			if(this.errors[key] !== undefined) return this.errors[key];
			return '';
		},
	}
}
</script>